import React from "react"
import { graphql } from "gatsby"
import ArticleList from "../components/ArticleList"

export default function CategoryListTemplate({ data }) {
    return (
      <ArticleList data={data} />
    )
  }

export const query = graphql`
query($category: [String]) {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {type: {eq: "Post"}, categories: {in: $category}}}
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            coverImage {
              childImageSharp {
                fixed(width: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            categories
            excerpt
          }
          fields {
            slug
          }
          excerpt(pruneLength: 500)
        }
      }
    }
  }
  
`